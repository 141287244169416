.storico{
  padding-top: 70px;
  display: block;
}

.storico-lista-anni{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.storico-lista-item{
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: rgb(236, 236, 236);
  border-radius: 30px;
  color:rgb(55,55,55);
  flex-grow: 0;
  flex:30%;
  margin-left: 10%;
  margin-right: 10%;
  transition: all .15s ease-in-out;
}
.storico-lista-last-item{
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: rgb(236, 236, 236);
  color:black;
  flex-grow: 0;
  flex:30%;
  margin-left: 33%;
  margin-right: 33%;
  transition: all .15s ease-in-out;
}

.storico-lista-item:hover{
  color: rgb(41, 183, 115);
  transform: scale(1.1);
}
.storico-lista-last-item:hover{
  color: rgb(41, 183, 115);
  transform: scale(1.1);
}

@media only screen and (max-width: 576px){
  .storico-lista-item{
    flex:80%;
  }
  .storico-lista-last-item{
    margin-left: 10%;
    margin-right: 10%;
  }
}