.about{
  padding-top: 70px;
  padding-left: 10%;
  padding-right: 10%;
  display: block;
  text-align: left;
}
.about-titolo{
  text-align: center;
}

.about-glossario{
  text-align: right;
  padding-bottom: 50px;
}

.about-glossario > h3{
  margin-right: 26px;
}

.about-idea-paragrafo{
  padding-top: 10px;
  margin-left: 20px;
}

.about-chisiamo{
  padding-top: 70px;
}
a{
  text-decoration: none;
  color: rgb(55,55,55);
  transition: all .15s ease-in-out;
}
a:hover{
  color: rgb(41, 183, 115);
}