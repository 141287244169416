.home{
  padding-top: 40px;
  display: block;
}

.home-title{
  font-size: 2.5rem;
  color: rgb(41, 183, 115);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 0 0;
  background-image: url(../assets/blurTitleHome.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.home-title-name{
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
}
.home-title-name-2{
  margin-top: -45px;
}

.home-title-button{
  background-color: rgb(41, 183, 115);
  border: none;
  font-size: 23px;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all .20s ease-in-out;
}

.home-title-button:hover{
  transform: scale(1.1);
}

.home-comunicato{
  font-size: 2rem;
  padding: 10px;
}

.p-home-comunicato{
  text-align: left;
  padding-left: 10px;
  font-size: 1.4rem;
  margin-top: -15px;
}

.home-comunicato-temp{
  text-align: left;
  font-size: 1.5rem;
  padding: 10px;
}

.p-home-comunicato-temp{
  text-align: left;
  padding-left: 10px;
  font-size: 1.2rem;
  margin-top: -15px;
}

.home-comunicato-temp-imgList{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}


.home-sponsor{
  display: block;
  background-image: url(../assets/PalestrinaBN.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 80%;
}
.home-sponsor-images{
  display: flex;
  justify-content: space-evenly;
  padding: 30px 20px;
}
.home-sponsor-images > img{
  height: 180px;
  width: auto;
}

.home-sponsor-title{
  padding-top: 10px;
  font-size: 2.7rem;
  color: rgb(18, 170, 97);
  text-align: left;
  padding-left: 20px;
}

.home-sponsor-diritto{
  color: white;
  text-align: right;
  padding-right: 20px;
}
.noLinkDeco{
  text-decoration: none;
}

@media screen and (max-width: 576px) {
  .home-title{
    display: block;
  }

  .home-sponsor-images{
    display: block;
  }

  .home-sponsor-images >img{
    padding: 20px 0;
    width: 60vw;
    height: auto;
  }
  .home-title > img{
    width: 100vw;
  }
}