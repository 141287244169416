.topic{
  padding-top: 70px;
  padding-left: 10%;
  padding-right: 10%;
  display: block;
  text-align: left;
  color: rgb(55, 55, 55);
}

.topic-video{
  align-content: center;
  width: 60%;
  margin-right: 20%;
  margin-left: 20%;
  height:320px;
}

.topic-video>iframe{
  width:100%;
}
.topic-titolo{
  text-align: left;
}

.topic-foto-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.topic-foto-placeholder{
  color: white;
}

.topic-foto-placeholder::selection{
  outline: none;
}

.topic-foto-placeholder:hover{
  cursor: default;
}

@media only screen and (max-width:576px){
 .topic-video{
  width: 100%;
  margin-right: 0%;
  margin-left: 0%;
  height: 250px;
  }
}