.header-option{
  margin-top: 17px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
  color: rgb(55,55,55);
  transition: all .15s ease-in-out;
}

.header-option:hover{
  color: rgb(41, 183, 115);
  transform: scale(1.1);
}

.header-option-title{
  font-size: 100%;
  object-fit: contain;
  margin-top: 5px;
  margin-bottom: 5px;
}
