.headerOG{
  position: fixed;
  top: 0px;
  background-color: white;
  margin: 0 0 0 0;
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  z-index: 10;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.header-left > img {
  margin-top: 5px;
  margin-left: 5px;
  object-fit: contain;
  height: 60px;
}

.desktop-menu {
  display: flex;
  justify-content: space-evenly;
}

.mobile-icon{
  display: none;
}
.noLinkDeco {
  text-decoration: none;
}

@media screen and (max-width: 750px) {
  .mobile-icon{
    display: flex;
  } 
  .desktop-menu{
    display: none;
  }

  .header-left > img{
    height: 39px;
  }
}