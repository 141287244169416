.hamburger{
  position: absolute;
  top: 15%;
  right: 1.5rem;
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
}

.hamburger>img{
  min-width: 100%;
}
.hamburger:focus{
  outline: none;
}