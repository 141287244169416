.imageTopic-imageDescription{
  margin-top: 3px;
  text-align: center;
  font-size: 0.95rem;
}

.imageTopic-box{
  align-content: center;
  margin-top: 30px;
  flex-grow: 0;
  flex:35%;
}

.imageTopic-image{
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1100px){
  .imageTopic-box{
    flex:100%;
  }
  .imageTopic-image > img {
    max-width: 100%;
    height: auto;
  }
}