.contact{
  padding-top: 70px;
  display: block;
}
.contact-box{
  margin-left: 20%;
  margin-right: 20%;
  border-style: solid;
  border-color: rgb(197, 197, 197);
  border-radius: 25px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 576px){
  .contact-box{
    margin-left: 7%;
    margin-right: 7%;
  }
}