.hamMenu{
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  border-bottom: 4px solid rgba(33, 85, 33, 0.671);
  border-right: 4px solid rgba(33, 85, 33, 0.671);
  height: 300%;
  text-align: right;
  padding: 2.5rem;
  position: absolute;
  top: 0;
  left: 99.2%;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-200%);
  width:99%;
}

.open{
  transform: translateX(-95%);
}

.noLinkDeco{
  text-decoration: none;
}

